import React from 'react'
import { Box, Card, IconButton, Menu, MenuItem } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  BackButton,
  BackIcon,
  Container,
  Details,
  Direction,
  Icons,
  LineButton,
  LineContainer,
  LineDetailsContainer,
  LineItem,
  LineNavbar,
  MoreHoursButton,
  MoreHoursIcon,
  Name,
  TextButton,
  TimeLineButton,
  TimeLineButtonText,
  TimelineContainer,
  TimeLineContentContainer,
  UpcomingArrivals
} from './styles'
import { setLineSelected, setShowLines } from '../../../actions/ui'
import { useLines } from '../../../hooks/useLines'
import { Icon, LineIcon } from '../Lines/styles'
import { ShareButtonsContainer } from '../Home/styles'
import ShareLargeButton from '../../../uicomponents/ShareLargeButton'
import { Map, StopTransfers } from '../../Line'

import {
  ArrowDropDown,
  CheckCircleOutlineRounded,
  ErrorRounded,
  FavoriteBorder,
  FavoriteRounded
} from '@mui/icons-material'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import CircularProgress from '@mui/material/CircularProgress'
import { handleStopClick } from '../../../actions/map'
import { logEvent } from '../../../firebase/firebase-config'
import UserProfilePreview from '../UserProfilePreview'
import { REACT_APP_HOST, REACT_APP_SHOW_LOGIN } from '../../../constants/config'

export const Line = ({
  routeSelected,
  map,
  setProviderModal,
  handleRouteSelect,
  anchorEl,
  setAnchorEl,
  handleScheduleClick,
  currentVehicles,
  setCurrentVehicles,
  showStopTooltip,
  setFilteredVehicles,
  vehiclesResponse,
  maxVisibleVehiclePositions,
  setSelectedStop
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    handleAddLineFav,
    handleRemoveLineFav
  } = useLines()

  const lineSelected = useSelector(state => state?.ui?.lineSelected)
  const routes = useSelector(state => state?.ui?.routes)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)

  const handleChangeSense = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickStop = (stop) => {
    dispatch(setShowLines(false))

    const transportType = cityConfig?.transport_types?.find(transport => transport?.route_type === lineSelected?.route_type)

    dispatch(handleStopClick({
      ...stop, transport_type_id: transportType?.transport_type_id
    }, userPosition, logEvent, auth.uid, navigate))
  }

  const icon = cityConfig?.transport_types?.find(type => lineSelected?.route_type === type?.route_type)?.flat_icon

  const addFavLine = (line) => {
    dispatch(setLineSelected({ ...lineSelected, fav: true }))
    handleAddLineFav(line)
  }

  const removeFavLine = (line) => {
    dispatch(setLineSelected({ ...lineSelected, fav: false }))
    handleRemoveLineFav(line)
  }

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>

      <Map
        routeSelected={routeSelected}
        map={map}
        setProviderModal={setProviderModal}
        currentVehicles={currentVehicles}
        setCurrentVehicles={setCurrentVehicles}
        showStopTooltip={showStopTooltip}
        setFilteredVehicles={setFilteredVehicles}
        vehiclesResponse={vehiclesResponse}
        maxVisibleVehiclePositions={maxVisibleVehiclePositions}
        setSelectedStop={setSelectedStop}
        handleClickStop={handleClickStop}
      />

      <LineContainer>
        {routes
          ? (
            <>
              <ShareButtonsContainer>
                <ShareLargeButton
                  eventName='path_share_clicked'
                  eventParams={{
                    user_id: auth?.uid,
                    os: 'web',
                    user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                    user_gender: userData ? userData?.gender : null,
                    user_lat: userPosition ? userPosition.lat : null,
                    user_lng: userPosition ? userPosition.lng : null,
                    city_id: cityConfig?.city_id,
                    path_id: routeSelected?.shape_id,
                    line_id: lineSelected?.route_id,
                    line_name: lineSelected?.route_short_name || lineSelected?.route_long_name,
                    going: routeSelected?.direction_id
                  }}
                  params={
                    {
                      link: `${REACT_APP_HOST}/lines/${cityConfig?.city_id}/${lineSelected?.route_id}/${routeSelected?.shape_id}?invitedby=${auth?.uid}&referrerName=${userData?.display_name ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=path_share_clicked`,
                      title: `${lineSelected?.route_short_name || lineSelected?.route_long_name}`,
                      description: t('share_route'),
                      picture: `${icon}`
                    }
}
                  shareText={t('share.title')}
                  shareType='share'
                />
                <ShareLargeButton
                  eventName='send_to_phone'
                  eventParams={{
                    user_id: auth?.uid,
                    os: 'web',
                    user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                    user_gender: userData ? userData?.gender : null,
                    user_lat: userPosition ? userPosition.lat : null,
                    user_lng: userPosition ? userPosition.lng : null,
                    city_id: cityConfig?.city_id,
                    path_id: routeSelected?.shape_id,
                    line_id: lineSelected?.route_id,
                    line_name: lineSelected?.route_short_name || lineSelected?.route_long_name,
                    going: routeSelected?.direction_id,
                    from: 'shape'
                  }}
                  params={
                    {
                      link: `${REACT_APP_HOST}/lines/${cityConfig?.city_id}/${lineSelected?.route_id}/${routeSelected?.shape_id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=path_share_clicked`,
                      title: `${lineSelected?.route_short_name || lineSelected?.route_long_name}`,
                      description: t('share_route'),
                      picture: icon
                    }
}
                  notificationBody={{
                    title: 'Recorrido',
                    content: `Recorrido ${lineSelected?.route_short_name || lineSelected?.route_long_name}`,
                    icon
                  }}
                  shareText={t('send_to_mobile.title')}
                  shareType='send-to-mobile'
                />
              </ShareButtonsContainer>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    boxShadow: '0px 0px 24px #22283126',
                    borderRadius: '16px',
                    margin: '0 0 16px 0',
                    height: '131px'
                  }}
                >
                  <LineItem>
                    <BackButton
                      sx={{
                        margin: '0 12px 0 0'
                      }}
                      onClick={() => navigate(-1)}
                    >
                      <BackIcon />
                    </BackButton>
                    <LineDetailsContainer>
                      <LineIcon
                        style={{ width: 32, height: 32, minHeight: 32, minWidth: 32 }}
                        color={`#${lineSelected?.route_color}`}
                      >
                        <Icon src={icon} />
                      </LineIcon>
                      <Details style={{ width: '80%' }}>
                        <Name>
                          {t('routes.line_route', { prop1: lineSelected?.route_short_name || lineSelected?.route_long_name })}
                        </Name>
                        <Direction>{routeSelected?.trip_headsign}</Direction>
                      </Details>
                      <Icons>
                        {routeSelected?.reports_affected.length >= 1
                          ? (
                            <ErrorRounded
                              sx={{ color: '#FFBB33', padding: '12px' }}
                            />
                            )
                          : (
                            <CheckCircleOutlineRounded
                              sx={{ color: '#5DCD8E', margin: '12px' }}
                            />
                            )}
                        {lineSelected?.fav
                          ? (
                            <IconButton
                              onClick={() => removeFavLine(lineSelected)}
                            >
                              <FavoriteRounded style={{ color: '#F05454' }} />
                            </IconButton>)
                          : (
                            <IconButton
                              onClick={() => addFavLine(lineSelected)}
                            >
                              <FavoriteBorder />
                            </IconButton>
                            )}
                      </Icons>
                    </LineDetailsContainer>
                  </LineItem>
                  <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        boxShadow: '0px 4px 12px #22283126',
                        borderRadius: '16px'
                      }
                    }}
                  >
                    {routes?.map((route, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handleRouteSelect(route)}
                        >
                          {route.trip_headsign}
                        </MenuItem>
                      )
                    })}
                  </Menu>
                  <LineNavbar>
                    <LineButton
                      onClick={handleChangeSense}
                      sx={{
                        color: '#222831',
                        fontSize: '14px',
                        textTransform: 'none',
                        fontWeight: 400,
                        textAlign: 'start',
                        justifyContent: 'initial',
                        width: '220px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'self-start',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <TextButton>
                        {routeSelected?.trip_headsign}
                      </TextButton>
                      <ArrowDropDown
                        sx={{
                          color: '#A6A9AC'
                        }}
                      />
                    </LineButton>
                  </LineNavbar>
                </Box>
                <Card
                  elevation={0}
                  sx={{
                    width: '100%',
                    height: 'calc(100% - 131px)',
                    borderRadius: '16px'
                  }}
                >
                  {routes &&
                    <TimelineContainer>
                      <Container>
                        <Timeline
                          align='left'
                          sx={{
                            width: '100%',
                            margin: 0,
                            padding: 0,
                            alignItems: 'flex-start'
                          }}
                        >
                          {routeSelected?.stops?.map((stop, index) => {
                            return (
                              <TimelineItem
                                key={stop?.id}
                                sx={{
                                  width: '100%',
                                  '&:before': {
                                    display: 'none'
                                  },
                                  height: '44px',
                                  minHeight: '44px'
                                }}
                              >
                                <TimelineSeparator>
                                  <TimelineDot
                                    sx={{
                                      border: `4px solid #${lineSelected?.route_color}`,
                                      margin: 0
                                    }}
                                    variant='outlined'
                                  />
                                  {routeSelected?.stops?.length !== index + 1 &&
                                    <TimelineConnector
                                      sx={{
                                        width: '4px',
                                        borderRadius: '4px',
                                        backgroundColor: `#${lineSelected?.route_color}`
                                      }}
                                    />}
                                </TimelineSeparator>
                                <TimeLineContentContainer
                                  style={{ position: 'relative' }}
                                >
                                  <TimelineContent
                                    sx={{
                                      top: '-12px',
                                      left: '25px',
                                      width: '100%',
                                      position: 'absolute',
                                      boxSizing: 'border-box',
                                      fontSize: '16px',
                                      padding: 0,
                                      marginRight: '10px !important'
                                    }}
                                  >
                                    <TimeLineButton
                                      sx={{
                                        width: '100%',
                                        color: '#222831',
                                        fontSize: '14px',
                                        textTransform: 'none',
                                        fontWeight: 400,
                                        borderRadius: '8px',
                                        justifyContent: 'initial',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'self-start',
                                        textOverflow: 'ellipsis',
                                        textAlign: 'start',
                                        position: 'relative'
                                      }}
                                      onClick={() => handleClickStop(stop)}
                                    >
                                      <TimeLineButtonText>
                                        {stop?.stop_name}
                                      </TimeLineButtonText>
                                      <StopTransfers
                                        transfers={stop?.transfers || []}
                                      />
                                    </TimeLineButton>
                                  </TimelineContent>
                                  <MoreHoursButton
                                    sx={{
                                      margin: 0,
                                      position: 'absolute',
                                      top: '-6px',
                                      right: '8px',
                                      color: 'white',
                                      display: 'flex',
                                      alignItems: 'center',
                                      zIndex: 800,
                                      padding: '0 0 0 10px',
                                      textTransform: 'none',
                                      fontWeight: '400'
                                    }}
                                    onClick={() => handleScheduleClick(stop)}
                                  >
                                    {t('routes.see_times')}
                                    <MoreHoursIcon />
                                  </MoreHoursButton>
                                  <UpcomingArrivals>
                                    Próximas llegadas
                                  </UpcomingArrivals>
                                </TimeLineContentContainer>

                              </TimelineItem>
                            )
                          })}
                        </Timeline>
                      </Container>
                    </TimelineContainer>}
                </Card>
              </Box>
            </>
            )
          : (
            <CircularProgress sx={{ color: 'primary.main' }} />
            )}
      </LineContainer>
      {REACT_APP_SHOW_LOGIN &&
        <UserProfilePreview />}
    </div>
  )
}
